import { domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class ScrollEvolution {
  constructor() {
    if (!domStorage.scrollEvolution) return

    this.DOM = { el: domStorage.scrollEvolution }
    this.DOM.line = this.DOM.el.querySelector(':scope > div')

    this.setEvolution()
  }

  setEvolution() {
    const { el, line } = this.DOM

    this.tl = gsap
      .timeline({
        defaults: { ease: 'none' },
        scrollTrigger: {
          trigger: viewStorage.current,
          start: 'top top',
          end: 'bottom bottom',
          scrub: true
        }
      })
      .fromTo(line, { clipPath: 'inset(0% 100% 0% 0%)' }, { clipPath: 'inset(0% 0% 0% 0%)' }, 0)
  }

  destroy() {
    this.tl && this.tl.kill()
  }
}
