/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from '@unseenco/taxi'
import { globalStorage } from './_globals'
import { onEnter, onEnterCompleted, onLeave, onLeaveCompleted } from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import FrontPage from './pages/front-page'
import PageAbout from './pages/page-about'
import PageContact from './pages/page-contact'
import SingleService from './pages/single-service'
import SingleInsight from './pages/single-insight'
import ArchiveInsights from './pages/archive-insights'

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  renderers: {
    default: Page,
    frontPage: FrontPage,
    pageAbout: PageAbout,
    pageContact: PageContact,
    singleService: SingleService,
    singleInsight: SingleInsight,
    archiveInsights: ArchiveInsights
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({ to, trigger }) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({ from, trigger }) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({ to, from, trigger }) => onEnterCompleted(to, from, trigger))
