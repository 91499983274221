import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import LoadMore from '../modules/load-more'

export default class ArchiveInsights extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      allInsightsSection: viewStorage.current.querySelector('.AllInsights'),
      cards: viewStorage.current.querySelectorAll('.InsightCard')
    }
    
    this.init()
  }

  init() {
    const { allInsightsSection = null, cards = null } = this.DOM

    // 1. Container, 2. Cards, 3. Limit, 4. Increment number for each time
    this.loadMoreModule = new LoadMore(allInsightsSection, cards, 12, 4)
  }

  onLeaveCompleted() {
    this.loadMoreModule && this.loadMoreModule.destroy()
  }
}
