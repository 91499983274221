import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

export const pageIntro = (delay = 0.5) => {
  const { body } = domStorage
  const { current } = viewStorage
  const currentPageDataset = current.dataset.taxiView

  // General
  const pageHeader = current.children[0]
  const title = pageHeader.querySelector('h1')
  const subtitle = pageHeader.querySelector('.overline-title.--subtitle')
  const button = pageHeader.querySelector('.Btn')
  const paragraph = pageHeader.querySelector('.container > p')
  const visual = pageHeader.querySelector('.container > .visual__container')
  const heroVisual = pageHeader.querySelector('.container > .visual__wrapper')
  const pageSections = [...current.children]
  const allSectionsExceptTheFirst = pageSections.slice(1)

  // Single Service
  const breadcrumb = pageHeader.querySelector('.Breadcrumb')

  // Single Insight
  const backLink = pageHeader.querySelector('.BackLink')
  const infosCategories = pageHeader.querySelectorAll('.Categories li')
  const infosSharingTitle = pageHeader.querySelector('.Sharing > span')
  const infosSharingItems = pageHeader.querySelectorAll('.Sharing li')

  // Contact
  const contactForm = pageHeader.querySelector('.Form')

  const tl = gsap
    .timeline({
      delay,
      defaults: { ease: 'power3.inOut', duration: 1 },
      onStart: () => {
        // Be sure Chrome reinit the scroll
        setTimeout(() => window.scrollTo(0, 0), 100)
      },
      onComplete: () => {
        // Cleaning styles
        if (subtitle) gsap.set(subtitle, { clearProps: 'all' })
        if (title) gsap.set(title, { clearProps: 'all' })
        if (button) gsap.set(button, { clearProps: 'all' })
        if (paragraph) gsap.set(paragraph, { clearProps: 'all' })
        if (visual) gsap.set(visual, { clearProps: 'all' })
        if (heroVisual) gsap.set(heroVisual, { clearProps: 'all' })
        if (allSectionsExceptTheFirst && allSectionsExceptTheFirst.length) gsap.set(allSectionsExceptTheFirst, { clearProps: 'all' })

        // Single Service
        if (breadcrumb) gsap.set(breadcrumb, { clearProps: 'all' })

        // Single Insight
        if (infosCategories && infosCategories.length) gsap.set(infosCategories, { clearProps: 'all' })
        if (infosSharingItems && infosSharingItems.length) gsap.set([infosSharingTitle, infosSharingItems], { clearProps: 'all' })
        if (backLink) gsap.set(backLink, { clearProps: 'all' })

        gsap.set(domStorage.header, { clearProps: 'all' })
      }
    })
    
    // General
    if (subtitle) tl.fromTo(subtitle, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, 0)
    if (title) tl.fromTo(title, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, subtitle ? 0.1 : 0)
    if (button) tl.fromTo(button, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, 0.25)
    if (paragraph) tl.fromTo(paragraph, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, 0.25)
    if (visual) tl.fromTo(visual, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, 0.3)
    if (heroVisual) tl.fromTo(heroVisual, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, 0.3)
    if (allSectionsExceptTheFirst && allSectionsExceptTheFirst.length) tl.fromTo(allSectionsExceptTheFirst, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, 0.4)

    // Single Service
    if (breadcrumb) tl.fromTo(breadcrumb, { opacity: 0 }, { opacity: 1 }, 0.5)
    if (currentPageDataset === 'singleService') tl.add(() => title.classList.add('--active'), 1)

    // Single Insight
    if (infosCategories && infosCategories.length) tl.fromTo(infosCategories, { y: 10, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.085 }, 0.35)
    if (infosSharingItems && infosSharingItems.length) tl.fromTo([infosSharingTitle, infosSharingItems], { y: 10, opacity: 0 }, { y: 0, opacity: 1, stagger: 0.085 }, 0.35)
    if (backLink) tl.fromTo(backLink, { opacity: 0 }, { opacity: 1 }, 0.5)

    // Contact
    if (contactForm) tl.fromTo(contactForm, { y: 10, opacity: 0 }, { y: 0, opacity: 1 }, 0.2)

    tl.fromTo(domStorage.header, { opacity: 0, yPercent: -100 }, { opacity: 1, yPercent: 0, duration: 0.7, ease: 'power2.inOut' }, 1)

  return tl
}
