import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Testimonials from '../modules/testimonials'

export default class SingleService extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      testimonialsSwiper: viewStorage.current.querySelector('.Testimonials .swiper')
    }
    
    this.init()
  }

  init() {
    const { 
      testimonialsSwiper = null
    } = this.DOM

    if (testimonialsSwiper) this.testimonialsSwiperModule = new Testimonials(testimonialsSwiper)
  }

  onLeaveCompleted() {
    this.testimonialsSwiperModule && this.testimonialsSwiperModule.destroy()
  }
}
