import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class AboutInfos{ 
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.gallery = this.DOM.container.querySelector('.gallery')
    this.DOM.visuals = this.DOM.container.querySelectorAll('.gallery > .visual__container')

    if (!this.DOM.visuals && !this.DOM.visuals.length) return

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setParallax()
  }

  setParallax() {
    const { gallery, visuals } = this.DOM

    this.mm.add(this.responsiveObj, context => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.tl = gsap
          .timeline({
            defaults: { ease: 'none' },
            scrollTrigger: {
              trigger: gallery,
              start: 'top bottom',
              end: 'bottom top',
              scrub: true
            }
          })
          .to(visuals, { yPercent: (index) => {
            switch (index) {
              case 0: return -20
              case 1: return 5
              case 2: return 50
              default: return -10
            }
          } }, 0)
      }
    })
  }

  destroy() {
    this.tl && this.tl.kill()
  }
}
