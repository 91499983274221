import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import AboutInfos from '../modules/about-infos'
import Testimonials from '../modules/testimonials'

export default class PageAbout extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      aboutLeaderlySection: viewStorage.current.querySelector('.AboutInfos.--about-leaderly'),
      testimonialsSwiper: viewStorage.current.querySelector('.Testimonials .swiper'),
    }
    
    this.init()
  }

  init() {
    const { 
      aboutLeaderlySection = null,
      testimonialsSwiper = null
    } = this.DOM

    if (aboutLeaderlySection) this.aboutInfosModule = new AboutInfos(aboutLeaderlySection)
    if (testimonialsSwiper) this.testimonialsSwiperModule = new Testimonials(testimonialsSwiper)
  }

  onLeaveCompleted() {
    this.aboutInfosModule && this.aboutInfosModule.destroy()
    this.testimonialsSwiperModule && this.testimonialsSwiperModule.destroy()
  }
}
