import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Marquee from '../modules/marquee'
import Testimonials from '../modules/testimonials'
import MobileSwiper from '../modules/mobile-swiper'
import WknSwiper from '../modules/wkn-swiper'
import LoadMore from '../modules/load-more'

export default class FrontPage extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      relatedServicesSwiper: viewStorage.current.querySelector('.RelatedServices .swiper.--mobile'),
      clientsSection: viewStorage.current.querySelector('.Clients'),
      clientCards: viewStorage.current.querySelectorAll('.ClientCard'),
      testimonialsSwiper: viewStorage.current.querySelector('.Testimonials .swiper'),
      relatedInsightsSwiper: viewStorage.current.querySelector('.RelatedInsights .swiper.--mobile')
    }
    
    this.init()
  }

  init() {
    const { 
      relatedServicesSwiper = null,
      clientsSection = null,
      clientCards = null,
      testimonialsSwiper = null,
      relatedInsightsSwiper = null 
    } = this.DOM

    if (relatedServicesSwiper) this.relatedServicesSwiperModule = new MobileSwiper(relatedServicesSwiper)
    if (clientsSection) this.clientsLoadMore = new LoadMore(clientsSection, clientCards, 14, 100)
    if (testimonialsSwiper) this.testimonialsSwiperModule = new Testimonials(testimonialsSwiper)
    if (relatedInsightsSwiper) this.relatedInsightsSwiperModule = new MobileSwiper(relatedInsightsSwiper)
  }

  onLeaveCompleted() {
    this.relatedServicesSwiperModule && this.relatedServicesSwiperModule.destroy()
    this.clientsLoadMore && this.clientsLoadMore.destroy()
    this.testimonialsSwiperModule && this.testimonialsSwiperModule.destroy()
    this.relatedInsightsSwiperModule && this.relatedInsightsSwiperModule.destroy()
  }
}
