import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(Observer, ScrollTrigger)
import HeaderSubmenus from './header-submenus'

// import HeaderDropdown from './header-dropdown'
// import HeaderSubmenus from './header-submenus'
// import HeaderSearchForm from './header-search-form'
// import ToolsModal from './tools-modal'

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.brand = this.DOM.el.querySelector('.brand')
    this.DOM.toggle = this.DOM.el.querySelector('.NavToggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')
    this.DOM.navSubmenus = this.DOM.el.querySelectorAll('.Nav__submenu')

    this.setEvents()
  }

  setEvents() {
    const { overlay, body } = domStorage
    const { 
      el, 
      toggle, 
      navDropdown,
      navSubmenus = null
    } = this.DOM

    /**
     * Events
     */
    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()
    this.toggle = this.toggle.bind(this)

    if (navSubmenus && navSubmenus.length) this.headerSubmenus = new HeaderSubmenus(el)

    this.setObserver()

    toggle.addEventListener('click', this.toggle)
    
    /**
     * Resize event to close menu
     */
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)
  }

  setObserver() {
    const { body } = domStorage
    const { el } = this.DOM

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: () => !el.classList.contains('--visible') && el.classList.add('--visible'),
      onDown: () => {
        el.classList.remove('--visible') 
        // We close all the submenus when the user scrolls (better UX)
        this.headerSubmenus && this.headerSubmenus.closeSubmenu()
      }
    })

    this.scrolledObserver = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 20 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
        if (window.scrollY <= 20 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
      }
    })
  }

  toggle(e) {
    e && e.preventDefault()

    globalStorage.menuOpen = !globalStorage.menuOpen

    globalStorage.menuOpen ? this.open() : this.close()
  }

  open() {
    globalStorage.menuOpen = true

    domStorage.body.classList.add('--show-menu')

    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

    this.DOM.menuContainer.setAttribute('data-lenis-prevent', '')
  }

  close() {
    globalStorage.menuOpen = false

    domStorage.body.classList.remove('--show-menu')

    viewStorage.viewScroll && viewStorage.viewScroll.scroll.start()

    this.DOM.menuContainer.hasAttribute('data-lenis-prevent') && this.DOM.menuContainer.removeAttribute('data-lenis-prevent')
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
