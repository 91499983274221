import { isDEVMODE, domStorage, globalStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from '../animations/page-intro'

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {
    gsap  
      .timeline({
        defaults: { duration: 1, ease: 'power3.inOut' },
        onStart: () => {
          // Be sure Chrome reinit the scroll
          setTimeout(() => window.scrollTo(0, 0), 250)
        },
        onComplete: () => {
          // Update body classNames
          domStorage.body.classList.remove('--animating')
          domStorage.header.classList.remove('--visible')

          // Close Header
          globalStorage.Header.close()
          
          // Refresh Scroll Triggers
          ScrollTrigger.refresh()
      
          // Display the page
          done()

          gsap.set([ to, domStorage.footer ], { clearProps: 'all' })

          // Display the Header once the new page is ready (to adapt the styles)
          gsap.to(domStorage.header, {  opacity: 1, yPercent: 0, ease: 'power2.inOut', clearProps: 'all', duration: 0.7 })
          gsap.set(domStorage.scrollEvolution, { clearProps: 'all' })
        }
      })
      .to([ to, domStorage.footer ], { opacity: 1, ease: 'power3' }, 0)
      .add(pageIntro(0.2), 0)
  }

  onLeave({ from, to, trigger, done }) {
    gsap
      .timeline({
        defaults: { duration: 1, ease: 'power3.inOut' },
        onStart: () => {
          domStorage.body.classList.remove('--scrolled')
          domStorage.header.classList.remove('--visible')

          globalStorage.closeMobileMenu()
        },
        onComplete: () => {
          // Update body classNames
          domStorage.body.classList.add('--animating')
          domStorage.body.classList.remove('--scrolled')

          // Remove the page
          done()
        }
      })
      .to(domStorage.header, { opacity: 0, yPercent: -100, duration: 0.7, ease: 'power2.inOut' }, 0)
      .to(domStorage.scrollEvolution, { scaleX: 0, transformOrigin: '0% 0%', duration: 0.7, ease: 'power2.inOut' }, 0)
      .to([ from, domStorage.footer ], { opacity: 0, ease: 'power3.inOut' }, 0)
  }
}
