import Swiper, { Navigation, Pagination, Scrollbar, EffectFade } from 'swiper'
Swiper.use([Navigation, Pagination, Scrollbar, EffectFade])

export default class Testimonials {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.swiperNavigation = this.DOM.container.querySelector('.swiper-navigation')

    this.setSwiper()
  }

  setSwiper() {
    const { container, swiperNavigation } = this.DOM

    this.swiper = new Swiper(container, {
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoHeight: true,
      navigation: {
        prevEl: swiperNavigation.querySelector('.prev'),
        nextEl: swiperNavigation.querySelector('.next')
      },
    })
  }

  destroy() {
    this.swiper && this.swiper.destroy()
  }
}
