import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Sharing from '../modules/sharing'
import WysiwygWithVideos from '../modules/wysiwyg-with-videos'
import MobileSwiper from '../modules/mobile-swiper'

export default class SingleInsight extends Renderer {
  initialLoad() {
    this.onEnter()
  }

  onEnter() {
    this.DOM = {
      pageHeader: viewStorage.current.querySelector('.PageHeaderSingleInsight'),
      content: viewStorage.current.querySelector('.wswyg--content'),
      relatedInsightsSwiper: viewStorage.current.querySelector('.RelatedInsights .swiper.--mobile')
    }
    
    this.init()
  }

  init() {
    const { 
      pageHeader = null,
      content = null,
      relatedInsightsSwiper = null 
    } = this.DOM

    if (pageHeader) this.sharingModule = new Sharing(pageHeader)
    if (content) this.wysiwygModule = new WysiwygWithVideos(content)
    if (relatedInsightsSwiper) this.relatedInsightsSwiperModule = new MobileSwiper(relatedInsightsSwiper)
  }

  onLeaveCompleted() {
    this.sharingModule && this.sharingModule.destroy()
    this.wysiwygModule && this.wysiwygModule.destroy()
    this.relatedInsightsSwiperModule && this.relatedInsightsSwiperModule.destroy()
  }
}
