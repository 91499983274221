import gsap from 'gsap'

export default class HeaderSubmenus {
  constructor(container) {
    this.DOM = { container }
    // Items with submenus
    this.DOM.navItems = this.DOM.container.querySelectorAll('.Nav__item.--with-submenu')

    if (!this.DOM.navItems && !this.DOM.navItems.length) return

    // Buttons to open submenus
    this.DOM.navItemsButtons = this.DOM.container.querySelectorAll('.Nav__item.--with-submenu > button.Nav__link')
    // Buttons to close submenus
    this.DOM.submenuCloseButtons = this.DOM.container.querySelectorAll('.CloseSubmenu')
    // Submenus
    this.DOM.navSubmenus = this.DOM.container.querySelectorAll('.Nav__item.--with-submenu .Nav__submenu')
    
    // Current Submenu
    this.currentSubmenu = null
    // Active ClassName
    this.className = '--active'
    // Body className
    this.bodyClassName = '--show-submenu'

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 1200
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setEvents()
  }

  setEvents() {
    const { navItems, navItemsButtons, submenuCloseButtons } = this.DOM

    this.openSubmenu = this.openSubmenu.bind(this)
    this.closeSubmenu = this.closeSubmenu.bind(this)
    this.clickOutsideSubmenu = this.clickOutsideSubmenu.bind(this)

    this.mm.add(this.responsiveObj, context => {
      const { isDesktop } = context.conditions

      // Add events to the context
      context.add('openSubmenu', this.openSubmenu)
      context.add('closeSubmenu', this.closeSubmenu)

      // Adding events depending of viewport sizes
      if (isDesktop) {
        // Each time we resize the window, we close the menu
        this.closeSubmenu()

        navItems.forEach(item => {
          item.addEventListener('mouseenter', context.openSubmenu)
          item.addEventListener('mouseleave', context.closeSubmenu)
        })
      } else {
        // Each time we resize the window, we close the menu
        this.closeSubmenu()

        navItemsButtons.forEach(button => button.addEventListener('click', context.openSubmenu))
      }

      return () => {
        // Cleaning the events
        navItems.forEach(item => {
          item.removeEventListener('mouseenter', context.openSubmenu)
          item.removeEventListener('mouseleave', context.closeSubmenu)
        })

        navItemsButtons.forEach(button => button.removeEventListener('click', context.openSubmenu))
      }
    })
  }

  openSubmenu(e) {
    e && e.preventDefault()

    const { navSubmenus } = this.DOM

    let parent
    let currentSubmenu

    // As we have 2 different events 
    // (on <li> with mouseenter/mouseleave and on <button> with click), 
    // we need to check which one is the current target :
    if (e.currentTarget.tagName === 'BUTTON') {
      parent = e.currentTarget.parentNode
      currentSubmenu = e.currentTarget.nextElementSibling
    } else {
      parent = e.currentTarget
      currentSubmenu = e.currentTarget.querySelector('.Nav__submenu')
    }

    // We need to hide all submenus
    navSubmenus.forEach(submenu => submenu.classList.remove(this.className))
    // Then we show only the current submenu
    currentSubmenu.classList.add(this.className)
    // We update the currentSubmenu variable 
    this.currentSubmenu = parent
    // We add the className to the body
    document.body.classList.add(this.bodyClassName)

    setTimeout(() => document.addEventListener('click', this.clickOutsideSubmenu), 50)
  }

  closeSubmenu(e) {
    e && e.preventDefault()

    // We remove the active className to the current submenu
    this.currentSubmenu && this.currentSubmenu.querySelector('.Nav__submenu').classList.remove(this.className)

    // We remove the className to the body
    document.body.classList.remove(this.bodyClassName)
    
    // We update the currentSubmenu variable 

    this.currentSubmenu = null
    // Remove event
    document.removeEventListener('click', this.clickOutsideSubmenu)
  }

  clickOutsideSubmenu(e) {
    e && e.preventDefault()

    if (!this.currentSubmenu.contains(e.target)) this.closeSubmenu()
  }
}
